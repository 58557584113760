import React, { Suspense, useEffect, useState } from 'react';
import Skeleton from './loading_skeleton';

const ProductCatalogContainer = React.lazy(() => {
  return import("productCatalog/ProductCatalogContainer");
});

const SkeletonLayout = () => {
  return (
    <div className="skeleton-layout">
      <Skeleton height="3rem" width="100%" />
      <Skeleton height="1.5rem" width="100%" />
      <Skeleton height="1.5rem" width="100%" />
      <Skeleton height="1.5rem" width="100%" />
    </div>
  );
};

const getUnparsedFacetsData = async () => {

  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const search_term = params.get('search[term]')
  const requestUrl = `/searches/get_unparsed_facets_data?search[term]=${encodeURIComponent(search_term)}`;
  const response = await fetch(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
    data: {
      search: { term: search_term },
    },
  });
  if (response.ok) {
    let facet_data = await response.json()
    return JSON.stringify(facet_data);
  } else {
    throw new Error("Unable to retrieve facets data");
  }
}

const getSelectedFacetsProps = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const facetsUrlParams = urlParams.get('search[facets_url_params]') || '';
  return facetsUrlParams || '';
}


const ProductCatalogComponent = (props) => {
  return (
    <div id='facets-component'>
      <Suspense fallback={<SkeletonLayout />}>
        <ProductCatalogContainer
          getUnparsedFacetsData={getUnparsedFacetsData}
          getSelectedFacetsProps={getSelectedFacetsProps}
          {...props}
        />
      </Suspense>
    </div>
  );
};

export default ProductCatalogComponent;
