import React, { Suspense } from 'react';

const OrderReviewContainer = React.lazy(() => {
  return import("shipping/OrderReviewContainer");
});

const OrderReviewComponent = (props) => {
  return (
    <div style={{ minHeight: 'calc(100vh - 397px)', textAlign: 'center', paddingTop: '40px' }}>
      <Suspense fallback={'Loading...'}>
        <OrderReviewContainer
          {...props}
        />
      </Suspense>
    </div>
  )
}

export default OrderReviewComponent;