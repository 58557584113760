import React, { Suspense } from 'react'

const CheckoutContainer = React.lazy(() => {
  return import("checkout/CheckoutContainer");
});

const CheckoutComponent = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CheckoutContainer {...props} />
    </Suspense>
  )
}

export default CheckoutComponent