import React, { Suspense } from 'react';

const PaymentsContainer = React.lazy(() => {
  return import("shipping/PaymentsContainer");
});

const PaymentsComponent = (props) => {
  function resultMessage(message) {
    console.log(message);
  }

  const handleSavePaymentInfo = async (paymentMethodType, paymentMethod) => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      const response = await fetch("/checkout/save_payment_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          paymentMethodType: paymentMethodType,
          paymentMethod: paymentMethod,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        if (props.checkoutType != 'paypal') {
          window.location.href = data.redirect;
        }
        return true
      } else {
        console.error(await response.json());
        return false
      }
    } catch (error) {
      console.error(error);
      resultMessage(`Could not save payment information...${error}`);
      return false
    }
  }

  return (
    <div style={{ minHeight: 'calc(100vh - 397px)', textAlign: 'center', paddingTop: '40px' }}>
      <Suspense fallback={'Loading...'}>
        <PaymentsContainer
          handleSavePaymentInfo={handleSavePaymentInfo}
          {...props}
        />
      </Suspense>
    </div>
  )
}

export default PaymentsComponent;